<template>
  <div class="relative z-20 h-[100svh] overflow-hidden">
    <h1 class="sr-only">{{ $t('generations.loading') }}</h1>
    <Loader class="flex h-full items-center justify-center" />
    <img
      src="/horse.gif"
      alt="Animated Horse"
      class="absolute -bottom-1 z-10 w-36 md:w-56"
    />
  </div>
</template>

<script setup>
import Loader from '../components/Loader.vue'
import { onUnmounted, ref, watchEffect, watch } from 'vue'
import {
  fetchSubmissionEmails,
  fetchIsWhitelisted,
} from '../services/formService'
import { email, credits, hasSound } from '../store'
import { loading, endLoading } from '../sounds'

watch(
  () => hasSound.value,
  () => {
    if (hasSound.value) {
      loading.play()
      setTimeout(() => {
        loading.stop()
        endLoading.play()
      }, 9500)
    } else {
      loading.stop()
    }
  }
)

onUnmounted(() => {
  loading.stop()
})

const userSubmissionEmail = ref()
const whitelistedEmails = ref()

const checkWhitelist = ref()

const main = async () => {
  await fetchSubmissionEmails(email.value).then((data) => {
    userSubmissionEmail.value = data.data
  })
  await fetchIsWhitelisted().then((data) => {
    whitelistedEmails.value = data.data

    checkWhitelist.value = whitelistedEmails.value.find(
      (el) => el.attributes.Email === email.value
    )
  })
}

main()

watchEffect(() => {
  if (userSubmissionEmail.value && whitelistedEmails.value) {
    if (checkWhitelist.value !== undefined) {
      credits.value = 2
    } else {
      credits.value = 2 - userSubmissionEmail.value?.length
      localStorage.setItem('generations', userSubmissionEmail.value?.length)
    }
  }
})
</script>

<style scoped>
img {
  animation: loading 10s linear forwards;
}

@keyframes loading {
  0% {
    left: -12%;
  }
  100% {
    left: 100%;
  }
}
</style>
