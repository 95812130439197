<template>
  <div
    class="pointer-events-none relative z-40 max-h-[100svh]"
    :class="hasScroll ? 'overflow-hidden' : ''"
  >
    <div
      class="block-notes pointer-events-auto relative top-16 z-10 font-base md:top-24 md:left-4 lg:left-[15rem] xl:left-[30rem] 2xl:left-[45rem]"
      :class="hasScroll ? 'h-screen' : ''"
    >
      <h1 class="sr-only">{{ $t('about.title') }}</h1>
      <BlockNotes
        bg-color="#B2CCFD"
        :text="$t('about.title')"
        :show-close-icon="true"
      >
        <p
          v-for="text in $tm('about.descriptionHans')"
          :key="text"
          class="mb-10 max-w-xl font-base text-2xl font-bold"
        >
          {{ text }}
        </p>

        <div
          class="flex max-w-xl space-x-10 font-label text-sm uppercase max-md:flex-col max-md:space-x-0 max-md:space-y-10"
        >
          <div class="description-project">
            <!-- <p class="mb-10">
              This project initiated within the AI research unit at <a href="https://domesticstreamers.com/" class="hover:text-white underline">Domestic
              Data Streamers</a> is used to understand the potential limitations and
              advantages of the use of GEN-AI within our creative processes and
              how it affects you our perception of value on creativity.
            </p>
            <p class="mb-10">
              For more information
              <a
                href="mailto:hello@domesticstreamers.com"
                class="font-bold underline hover:text-white"
                >email us!</a
              >
            </p>
            <p>
              More GEN-AI experimental tools: 
              <ul>
                <li><a href="https://soundframes.org/" class="hover:text-white underline">Soundframes: A voice-to-image generator.</a></li>
              </ul>
            </p> -->
            <p
              v-for="text in $tm('about.descriptionProject')"
              class="mb-10"
              v-html="text"
            ></p>
          </div>
        </div>
      </BlockNotes>
    </div>
  </div>
</template>

<script setup>
import BlockNotes from '../components/BlockNotes.vue'
import { ref, onMounted, watch, onUnmounted } from 'vue'
import { hasSound } from '../store'
import { idle, printer } from '../sounds'

watch(
  () => hasSound.value,
  () => {
    if (hasSound.value) {
      idle.play()
    } else {
      printer.stop()
      idle.stop()
    }
  }
)

const hasScroll = ref(true)

onMounted(() => {
  if (hasSound.value) {
    printer.play()
  }
  setTimeout(() => {
    hasScroll.value = false
  }, 4000)
})

onUnmounted(() => {
  idle.stop()
})
</script>

<style scoped>
@keyframes form {
  0% {
    transform: translateY(100vh);
  }
  15% {
    transform: translateY(80vh);
  }
  30% {
    transform: translateY(60vh);
  }
  45% {
    transform: translateY(40vh);
  }
  60% {
    transform: translateY(20vh);
  }
  75% {
    transform: translateY(10vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

.block-notes {
  animation-name: form;
  animation-duration: 4s;
}

.description-project p:last-child {
  @apply mb-0;
}
</style>
