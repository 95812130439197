import qs from 'qs'

import { BASE_API_URL } from '../constants'
import { fetchData, postData } from '../services/api'

export async function fetchReport(userId) {
  const query = qs.stringify(
    {
      populate: {
        Idea: {
          populate: {
            Image: {
              populate: '*',
            },
          },
        },
        PDF: {
          populate: '*',
        },
      },
      filters: {
        id: {
          $eq: userId,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  )
  return await fetchData(BASE_API_URL + `/user-reports?${query}`)
}

export async function fetchSubmission(email) {
  const query = qs.stringify(
    {
      populate: '*',
      filters: {
        Email: {
          $eq: email,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  )
  return await fetchData(BASE_API_URL + `/user-submissions?${query}`)
}

export async function fetchSubmissionEmails(email) {
  const query = qs.stringify(
    {
      populate: '*',
      filters: {
        Email: {
          $eq: email,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  )
  return await fetchData(BASE_API_URL + `/user-submissions?${query}`)
}

export async function fetchIsWhitelisted() {
  const query = qs.stringify(
    {
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    }
  )
  return await fetchData(BASE_API_URL + `/is-whitelisteds?${query}`)
}

export async function fetchPdf() {
  return await fetchData(BASE_API_URL + `/pdf`)
}

export async function postForm(data) {
  return await postData(BASE_API_URL + '/generate-report', data, true)
}

export async function postProForm(data) {
  return await postData(BASE_API_URL + '/interested-pro-users', data, false)
}
