<template>
  <nav
    aria-label="Primary Navigation"
    class="navbar fixed top-0 z-40 w-full bg-transparent px-8 py-6 font-label text-sm uppercase md:text-base 2xl:text-xl"
    :style="`color: ${textColor}; background: ${bgColor}`"
  >
    <ul class="flex justify-between">
      <li class="max-md:flex max-md:items-center">
        <router-link to="/">Clever Hans</router-link>
      </li>
      <li aria-hidden="true">
        <p class="max-md:hidden">
          <time>{{ time }}</time
          >, {{ city }}
        </p>
      </li>
      <li class="max-md:hidden">
        <div class="flex items-center space-x-6">
          <p aria-hidden="true">{{ $t('navbar.sound') }}</p>
          <button
            @click="onSound"
            :aria-pressed="hasSound"
            class="relative h-5 w-9 rounded-full border-2 bg-transparent"
            :style="`border-color: ${textColor}`"
          >
            <p class="sr-only">{{ $t('navbar.sound') }}</p>
            <div
              class="sound-button h-3.5 w-3.5 rounded-full"
              :style="`background: ${textColor}`"
              :class="hasSound ? ' translate-x-4' : 'translate-x-0.5'"
            ></div>
          </button>
        </div>
      </li>
      <li class="max-md:flex max-md:items-center">
        <router-link to="/about">{{ $t('navbar.about') }}</router-link>
      </li>
      <li>
        <span class="sr-only">{{ $t('navbar.lang') }}</span>
        <div>
          <select class="bg-transparent" v-model="$i18n.locale">
            <option
              v-for="locale in $i18n.availableLocales"
              :key="`locale-${locale}`"
              :value="locale"
            >
              {{ locale }}
            </option>
          </select>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { hasSound } from '../store'

import { useI18n } from 'vue-i18n'

const { locale } = useI18n()

watch(
  () => locale.value,
  () => {
    document.cookie = `locale=${locale.value}`
  }
)

const time = ref()

defineProps<{
  textColor: String
  bgColor?: String
  city: String
}>()

onMounted(() => {
  document.cookie = `locale=${locale.value}`

  time.value = new Date().toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })

  setInterval(() => {
    time.value = new Date().toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })
  }, 1000)
})

const onSound = () => {
  hasSound.value = !hasSound.value
}
</script>

<style scoped>
.sound-button {
  transition: transform 0.5s linear;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
</style>
