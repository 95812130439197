<template>
  <div class="flex h-full items-center justify-center">
    <Transition>
      <div
        v-if="!success"
        class="mx-auto flex max-w-sm flex-col items-center space-y-10 md:max-w-5xl"
        :style="`color: ${textColor}`"
      >
        <p
          class="text-center font-base font-bold"
          :style="`font-family: '${fontFamily}', sans-serif; text-wrap: balance;`"
          :class="[
            fontSize ? 'max-w-xl' : 'max-w-2xl',
            noCredits
              ? 'sm:text-md text-sm md:text-lg 2xl:text-xl'
              : 'text-base sm:text-2xl md:text-4xl 2xl:text-5xl',
          ]"
        >
          {{ $t('access.description') }}
        </p>
        <form
          @submit.prevent="nextStep"
          class="flex max-md:flex-col max-md:space-y-3 md:space-x-3"
        >
          <div>
            <label for="email" class="sr-only">Email</label>
            <input
              type="email"
              id="email"
              class="min-w-[320px] px-4 py-3 md:h-full md:min-w-[520px]"
              :class="
                fontSize
                  ? 'border-b-2 bg-transparent'
                  : 'rounded-full bg-gray-300'
              "
              placeholder="E-mail 🏇"
              v-model="email"
              required
              autocomplete="email"
            />
          </div>
          <button
            type="submit"
            class="rounded-full px-10 py-3 font-label uppercase"
            :class="
              textColor !== 'white'
                ? 'bg-black text-white transition-colors duration-300 hover:bg-white hover:text-black'
                : 'bg-white text-black transition-colors duration-300 hover:bg-black hover:text-white'
            "
          >
            {{ $t('access.button') }}
          </button>
        </form>
        <div
          v-if="error !== ''"
          class="mx-7 flex items-center justify-center rounded-full bg-red-300 py-2 px-4 text-center font-label text-white"
        >
          {{ error }}
        </div>
      </div>

      <div
        v-else
        class="mx-auto flex max-w-sm items-center delay-500 md:max-w-5xl"
        style="text-wrap: balance"
      >
        <p
          class="text-center font-base text-2xl font-bold md:text-5xl 2xl:text-6xl"
          :class="textColor !== 'white' ? 'text-black' : 'text-white'"
        >
          {{ $t('access.result') }}
        </p>
      </div>
    </Transition>

    <div role="region" aria-live="polite" class="sr-only">
      <div v-if="!success && error !== ''">
        {{ error }}
      </div>
      <div v-if="success">
        {{ $t('access.result') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { postProForm } from '../services/formService'

defineProps<{
  textColor: string
  fontFamily: string
  fontSize: string
  noCredits?: boolean
}>()

const email = ref()
const success = ref(false)
const error = ref('')

const nextStep = () => {
  postProForm({ Email: email.value })
    .then((res) => {
      success.value = true
    })
    .catch((err) => {
      error.value = err
    })
}
</script>

<style scoped>
[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 2s 0.2s ease;
  text-indent: -100%;
}

[placeholder] {
  @apply font-label;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}

.v-leave-to,
.v-enter-from {
  opacity: 0;
}
</style>
