<template>
  <div class="relative z-20 h-[100svh] overflow-hidden">
    <div class="flex h-full items-center justify-center px-14">
      <div
        class="mx-auto flex max-w-sm flex-col items-center text-center md:max-w-5xl"
        :class="
          credits > 0 ? 'space-y-12 md:space-y-36' : 'space-y-5 md:space-y-12'
        "
      >
        <div class="font-base text-2xl font-bold text-white md:text-6xl">
          <h1 class="pb-3">{{ $t('results.done') }}</h1>
          <p>{{ $t('results.sent') }}</p>
        </div>

        <div
          v-if="credits > 0"
          class="flex flex-col items-center space-y-9 font-label uppercase md:space-y-7"
        >
          <router-link
            to="/brief"
            class="flex h-14 items-center justify-center rounded-full bg-white px-10 text-black transition-colors duration-300 hover:bg-black hover:text-white"
          >
            {{ $t('results.anotherExpander') }}
          </router-link>
          <p class="text-white">
            {{ $t('results.expanderButton[0]') }} {{ credits }}
            {{ $t('results.expanderButton[1]') }}
          </p>
        </div>

        <div v-else>
          <SendEmail
            text-color="white"
            font-family="FavoritMono"
            font-size="24"
            :no-credits="true"
          />
        </div>
        <button
          v-if="detectMobileDevice()"
          ref="shareButtonRef"
          class="flex items-center gap-x-3 rounded-full bg-white px-5 py-3 font-label hover:bg-blue-300"
          @click="onShareButton"
        >
          Share!
          <img
            src="../assets/share.svg"
            width="18"
            height="18"
            alt="Share Icon"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import SendEmail from '../components/SendEmail.vue'
import { credits } from '../store'
import { ref } from 'vue'

const shareButtonRef = ref()

const onShareButton = () => {
  if (navigator.share) {
    navigator
      .share({
        url: 'https://clever-hans.com/',
        title: document.title,
        text: 'Clever Hans AI',
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error))
  } else {
    console.log('Share not supported on this browser, do it the old way.')
  }
}

const detectMobileDevice = () => {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  return regex.test(navigator.userAgent)
}
</script>
