<template>
  <div
    class="pointer-events-none relative z-40 max-h-[100svh]"
    :class="hasScroll ? 'overflow-hidden' : ''"
  >
    <div
      class="block-notes pointer-events-auto relative top-16 z-10 font-base md:top-24 md:left-4 lg:left-16 xl:left-32 2xl:left-44"
    >
      <BlockNotes
        bg-color="#F3E900"
        :expander-text="$t('brief.expander')"
        :text="$t('brief.paso')"
        :show-close-icon="true"
      >
        <h1 class="pb-10 text-3xl font-bold md:pb-16 md:text-6xl">
          {{ $t('brief.title') }}
        </h1>
        <form
          aria-label="Brief form"
          @submit.prevent="nextStep"
          class="space-y-8"
        >
          <div class="flex items-center space-x-5">
            <h2
              v-if="countGenerations < 1"
              class="pb-2 text-xl font-bold md:pb-5 md:text-2xl"
            >
              {{ $t('brief.fields.aboutUser.title') }}
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              class="feather feather-corner-right-down"
            >
              <path d="m10 15 5 5 5-5" />
              <path d="M4 4h7a4 4 0 0 1 4 4v12" />
            </svg>
          </div>
          <div v-if="countGenerations < 1" class="space-y-8">
            <div class="flex flex-col space-y-3 border-b border-black pb-20">
              <label for="role" class="text-lg font-bold md:text-xl"
                >{{ $t('brief.fields.aboutUser.role')
                }}<span class="text-red-500">*</span></label
              >
              <select
                name="role"
                id="role"
                class="w-fit bg-transparent py-3 pr-4 font-label"
                v-model="role"
                required
              >
                <option disabled value="undefined">
                  {{ $t('brief.placeholders.format') }}
                </option>
                <option value="freelance-designer">Freelance Designer</option>
                <option value="freelance-copywriter">
                  Freelance copywriter
                </option>
                <option value="ceo">CEO, COO, CMO, CTO</option>
                <option value="creative-director">Creative Director</option>
                <option value="account-manager">Account Manager</option>
                <option value="designer">Designer</option>
                <option value="copywriter">Copywriter</option>
                <option value="developer">Developer</option>
                <option value="intern">Intern</option>
                <option value="other">Other</option>
              </select>
              <input
                type="text"
                v-if="role === 'other'"
                v-model="otherRole"
                class="bg-transparent py-3 font-label"
                :placeholder="$t('brief.placeholders.specify')"
                required
              />
            </div>

            <div class="flex flex-col space-y-1 border-b border-black pb-20">
              <label for="location" class="text-lg font-bold md:text-xl"
                >{{ $t('brief.fields.aboutUser.location')
                }}<span class="text-red-500">*</span></label
              >
              <input
                type="text"
                id="location"
                class="bg-transparent py-3 font-label"
                :placeholder="$t('brief.placeholders.writeAnswer')"
                v-model="location"
                required
              />
            </div>

            <div class="flex flex-col space-y-1 border-b border-black pb-20">
              <fieldset>
                <legend class="text-lg font-bold md:text-xl">
                  {{ $t('brief.fields.aboutUser.tools')
                  }}<span class="text-red-500">*</span>
                </legend>

                <div class="flex flex-col space-y-2 py-3 font-label">
                  <label>
                    <input type="radio" v-model="tools" value="yes" />
                    {{ $t('brief.placeholders.yes') }}
                  </label>

                  <label>
                    <input type="radio" v-model="tools" value="no" />
                    {{ $t('brief.placeholders.no') }}
                  </label>
                </div>
              </fieldset>
            </div>

            <div class="flex flex-col space-y-1 border-b border-black pb-20">
              <label for="role" class="text-lg font-bold md:text-xl"
                >{{ $t('brief.fields.aboutUser.discover') }}
                <span class="text-red-500">*</span></label
              >
              <select
                name="discover"
                id="discover"
                class="w-fit bg-transparent py-3 pr-4 font-label"
                v-model="discover"
                required
              >
                <option disabled value="undefined">
                  {{ $t('brief.placeholders.format') }}
                </option>
                <option value="instagram">Instagram</option>
                <option value="facebook">Facebook</option>
                <option value="linkedin">LinkedIn</option>
                <option value="twitter">Twitter</option>
                <option value="friend">Friend</option>
                <option value="other">Other</option>
              </select>
              <input
                type="text"
                v-if="discover === 'other'"
                v-model="otherDiscover"
                class="bg-transparent py-3 font-label"
                :placeholder="$t('brief.placeholders.specify')"
                required
              />
            </div>
          </div>

          <div class="flex items-center space-x-5">
            <h2 class="pb-2 text-xl font-bold md:pb-5 md:text-2xl">
              {{ $t('brief.fields.idea.title') }}
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              class="feather feather-corner-right-down"
            >
              <path d="m10 15 5 5 5-5" />
              <path d="M4 4h7a4 4 0 0 1 4 4v12" />
            </svg>
          </div>

          <div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="project" class="text-lg font-bold md:text-xl"
              >{{ $t('brief.fields.idea.project')
              }}<span class="text-red-500">*</span></label
            >
            <input
              type="text"
              id="project"
              class="bg-transparent py-3 font-label"
              :placeholder="$t('brief.placeholders.writeAnswer')"
              v-model="project"
              required
            />
          </div>
          <div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="format" class="text-lg font-bold md:text-xl"
              >{{ $t('brief.fields.idea.workOn')
              }}<span class="text-red-500">*</span></label
            >
            <select
              name="format"
              id="format"
              class="w-fit bg-transparent py-3 pr-4 font-label"
              v-model="format"
              required
            >
              <option disabled value="undefined">
                {{ $t('brief.placeholders.format') }}
              </option>
              <option value="installation">Installation</option>
              <option value="exhibition">Exhibition</option>
              <option value="digital-experience">Digital Experience</option>
              <option value="website">Website</option>
            </select>
          </div>
          <div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="research" class="text-lg font-bold md:text-xl"
              >{{ $t('brief.fields.idea.research') }}
              <span class="text-red-500">*</span></label
            >
            <input
              type="text"
              id="research"
              class="bg-transparent py-3 font-label"
              :placeholder="$t('brief.placeholders.writeAnswer')"
              v-model="research"
              required
            />
          </div>
          <div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="goal" class="text-lg font-bold md:text-xl"
              >{{ $t('brief.fields.idea.goal')
              }}<span class="text-red-500">*</span></label
            >
            <input
              type="text"
              id="goal"
              class="bg-transparent py-3 font-label"
              :placeholder="$t('brief.placeholders.writeAnswer')"
              v-model="goal"
              required
            />
          </div>
          <!-- <div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="message" class="text-lg font-bold md:text-xl">{{
              $t('brief.fields.idea.keyMessage')
            }}</label>
            <input
              type="text"
              id="message"
              class="bg-transparent py-3 font-label"
              :placeholder="$t('brief.placeholders.writeAnswer')"
              v-model="message"
            />
          </div> -->
          <!-- <div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="data" class="text-lg font-bold md:text-xl"
              >Data to show...</label
            >
            <input
              type="text"
              id="data"
              class="bg-transparent py-3 font-label"
              placeholder="Write your answer here"
              v-model="dataToShow"
              
            />
          </div> -->
          <!--<div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="mood" class="text-lg font-bold md:text-xl">{{
              $t('brief.fields.idea.mood')
            }}</label>
            <input
              type="text"
              id="mood"
              class="bg-transparent py-3 font-label"
              :placeholder="$t('brief.placeholders.writeAnswer')"
              v-model="mood"
            />
          </div>-->
          <!-- <div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="requirements" class="text-lg font-bold md:text-xl">{{
              $t('brief.fields.idea.requirements')
            }}</label>
            <input
              type="text"
              id="requirements"
              class="bg-transparent py-3 font-label"
              :placeholder="$t('brief.placeholders.writeAnswer')"
              v-model="requirements"
            />
          </div> -->
          <div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="expertise" class="text-lg font-bold md:text-xl"
              >{{ $t('brief.fields.idea.expertise') }}
              <span class="text-red-500">*</span></label
            >
            <select
              name="expertise"
              id="expertise"
              class="w-fit bg-transparent py-3 pr-4 font-label"
              v-model="expertise"
              required
            >
              <option disabled value="undefined">
                {{ $t('brief.placeholders.format') }}
              </option>
              <option value="installation">Designer</option>
              <option value="exhibition">Paintor</option>
              <option value="poet">Poet</option>
              <option value="artist">Artist</option>
              <option value="exhibition-desginer">Exhibition designer</option>
              <option value="arquitect">Arquitect</option>
              <option value="engineer">Engineer</option>
              <option value="web-consultant">Web consultant</option>
              <option value="antropologist">Antropologist</option>
              <option value="journalist">Journalist</option>
              <option value="expert-gamification">
                Expert on gamification
              </option>
            </select>
          </div>
          <div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="email" class="text-lg font-bold md:text-xl"
              >{{ $t('brief.fields.idea.email')
              }}<span class="text-red-500">*</span></label
            >
            <input
              type="email"
              id="email"
              class="bg-transparent py-3 font-label"
              :placeholder="$t('brief.placeholders.writeAnswer')"
              v-model="email"
              required
              autocomplete="email"
            />
          </div>
          <div class="flex flex-col space-y-1 border-b border-black pb-20">
            <label for="language" class="text-lg font-bold md:text-xl"
              >{{ $t('brief.fields.idea.language') }}
              <span class="text-red-500">*</span></label
            >
            <select
              name="language"
              id="language"
              class="w-fit bg-transparent py-3 pr-4 font-label"
              v-model="language"
              required
            >
              <option disabled value="undefined">
                {{ $t('brief.placeholders.format') }}
              </option>
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="cat">Català</option>
            </select>
          </div>

          <div role="region" aria-live="polite">
            <p
              v-if="showError"
              class="text-md font-label text-red-500 md:text-lg"
            >
              {{ error || 'Something goes wrong! :(' }}
            </p>
          </div>

          <div class="flex justify-end">
            <button
              type="submit"
              class="rounded-full bg-black py-3 px-7 font-label uppercase transition-colors duration-300 hover:bg-white"
              style="color: #f3e900"
              @click="onClickSound"
            >
              {{ $t('brief.sendButton') }}
            </button>
          </div>
        </form>
      </BlockNotes>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue'
import BlockNotes from '../components/BlockNotes.vue'
import { postForm } from '@/services/formService'
import { useRouter } from 'vue-router'
import { email, hasSound } from '../store'
import { printer, send, idle } from '../sounds'

const countGenerations = ref()

countGenerations.value = localStorage.generations
  ? Number(localStorage.generations)
  : 0

watch(
  () => hasSound.value,
  () => {
    if (hasSound.value) {
      idle.play()
    } else {
      printer.stop()
      idle.stop()
    }
  }
)

const router = useRouter()

const project = ref()
const format = ref()
const research = ref()
const goal = ref()
// const message = ref()
const dataToShow = ref('')
// const mood = ref()
// const requirements = ref()
const expertise = ref()

const role = ref()
const otherRole = ref()
const location = ref()
const tools = ref()
const discover = ref()
const otherDiscover = ref()

const language = ref()

const showError = ref(false)
const error = ref()

onUnmounted(() => {
  idle.stop()
})

const nextStep = () => {
  postForm({
    Email: email.value,
    project: project.value,
    research: research.value,
    workOn: format.value,
    goal: goal.value,
    // keyMessage: message.value,
    dataToShow: dataToShow.value,
    // mood: mood.value,
    // requirements: requirements.value,
    expertise: expertise.value,
    role: role.value,
    otherRole: otherRole.value ? otherRole.value : 'null',
    location: location.value,
    tools: tools.value,
    discover: discover.value,
    otherDiscover: otherDiscover.value ? otherDiscover.value : 'null',
    language: language.value,
  })
    .then((res) => {
      if (res?.message === 'ok') {
        if (hasSound.value) {
          send.play()
        }
        router.push('/generating')
      } else {
        console.log(res)
        showError.value = true
      }
    })
    .catch((err) => {
      console.log(err)
      error.value = err
      showError.value = true
    })
}
const hasScroll = ref(true)

onMounted(() => {
  if (hasSound.value) {
    printer.play()
  }

  setTimeout(() => {
    hasScroll.value = false
  }, 4000)
})
</script>

<style scoped>
input::placeholder {
  opacity: 0.4;
  @apply font-label;
}

@keyframes form {
  0% {
    transform: translateY(100vh);
  }
  15% {
    transform: translateY(80vh);
  }
  30% {
    transform: translateY(60vh);
  }
  45% {
    transform: translateY(40vh);
  }
  60% {
    transform: translateY(20vh);
  }
  75% {
    transform: translateY(10vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

.block-notes {
  animation-name: form;
  animation-duration: 4s;
}
</style>
