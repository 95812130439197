import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/home.vue'
import brief from '../views/brief.vue'
import about from '../views/about.vue'
import access from '../views/access.vue'
import generating from '../views/generating.vue'
import result from '../views/result.vue'
import pdf from '../views/pdf.vue'
import credits from '../views/credits.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: home,
      meta: {
        textColor: 'white',
        textColorFooter: 'white',
        background: 'video',
      },
    },
    {
      path: '/brief',
      name: 'brief',
      component: brief,
      meta: {
        textColor: 'black',
        textColorFooter: 'black',
        background: 'white',
      },
      beforeEnter: () => {
        if (Number(localStorage.generations) > 1) {
          return { name: 'credits' }
        }
      },
    },
    {
      path: '/about',
      name: 'about',
      component: about,
      meta: {
        textColor: 'black',
        textColorFooter: 'black',
        background: 'white',
      },
    },
    {
      path: '/access',
      name: 'access',
      component: access,
      meta: {
        textColor: 'black',
        textColorFooter: 'black',
        background: 'white',
      },
    },
    {
      path: '/generating',
      name: 'generating',
      component: generating,
      meta: {
        textColor: 'white',
        textColorFooter: 'white',
        opacity: true,
        background: 'black',
      },
    },
    {
      path: '/result',
      name: 'result',
      component: result,
      meta: {
        textColor: 'white',
        textColorFooter: 'black',
        background: 'image',
      },
    },
    {
      path: '/pdf/:id',
      name: 'pdf',
      component: pdf,
      meta: {
        textColor: 'transparent',
        textColorFooter: 'transparent',
        background: 'white',
      },
    },
    {
      path: '/credits',
      name: 'credits',
      component: credits,
      meta: {
        textColor: 'white',
        textColorFooter: 'white',
        background: 'black',
      },
    },
  ],
})

export default router
