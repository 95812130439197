<template>
  <section class="flex flex-col items-center justify-center text-white">
    <div class="pb-10">
      <span class="font-label text-xl md:text-3xl">{{ percentage }}%</span>
    </div>
    <p
      class="px-14 text-center font-base text-2xl font-bold md:max-w-5xl md:text-7xl"
    >
      {{ $t(`generations.texts[${randomNumbers[index]}]`) }}
    </p>
  </section>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const percentage = ref(0)

//TODO DINAMICO -> $t('generations.texts').length
const randomNumbers = [0, 1, 2, 3]
  .slice(0, 4)
  .sort((a, b) => 0.5 - Math.random())
  .slice(0, 3)

console.log(randomNumbers)

const index = ref(0)

setInterval(() => {
  if (percentage.value < 100) {
    percentage.value++
  }
}, 100)

watchEffect(() => {
  if (percentage.value === 100) router.push('/result')
})

//TODO DINAMICO -> $t('generations.texts').length
setInterval(() => {
  if (index.value < randomNumbers.length - 1) index.value++
}, 3400)
</script>
