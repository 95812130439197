<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.35355"
      y1="0.646447"
      x2="16.3536"
      y2="15.6464"
      stroke="currentColor"
    />
    <line
      x1="0.646447"
      y1="15.6464"
      x2="15.6464"
      y2="0.646447"
      stroke="currentColor"
    />
  </svg>
  <template> </template>
</template>
