export const fetchData = async (url) => {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  try {
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data?.error?.message || "Network response was not OK");
    }
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postData = async (url, data, withoutDataParam) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: withoutDataParam
      ? JSON.stringify(data)
      : JSON.stringify({
          data: data,
        }),
  });
  try {
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data?.error?.message || "Network response was not OK");
    }

    return data;
  } catch (error) {
    throw new Error(error);
  }
};
