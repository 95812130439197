<template>
  <div class="relative z-20 h-[100svh] overflow-hidden max-md:pb-24">
    <h1 class="sr-only">{{ $t('access.title') }}</h1>
    <SendEmail text-color="black" font-family="NeueMontreal" />
  </div>
</template>

<script setup>
import SendEmail from '../components/SendEmail.vue'
import { onUnmounted } from 'vue'
import { idle } from '../sounds'

onUnmounted(() => {
  idle.stop()
})
</script>

<style scoped>
[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 2s 0.2s ease;
  text-indent: -100%;
}

input::placeholder {
  @apply font-label;
}
</style>
