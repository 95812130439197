import { Howl } from "howler";

export const printer = new Howl({
  src: ["/sounds/01_Printer.mp3"],
  volume: 0.8,
  autoplay: false,
});

export const idle = new Howl({
  src: ["/sounds/02_Idle.mp3"],
  volume: 0.8,
  autoplay: false,
});

export const send = new Howl({
  src: ["/sounds/03_Send.mp3"],
  volume: 0.8,
  autoplay: false,
});

export const loading = new Howl({
  src: ["/sounds/04_Loading.mp3"],
  volume: 0.8,
  autoplay: false,
});

export const endLoading = new Howl({
  src: ["/sounds/05_End_Loading.mp3"],
  volume: 0.8,
  autoplay: false,
});
