<template>
  <!-- <RouterView /> -->
  <NavBar
    :text-color="$route?.meta?.textColor"
    :bg-color="$route?.meta?.bgColor"
    :city="city"
  />

  <router-view v-slot="{ Component, route }">
    <Transition :name="route.meta.transition || 'fade-page'" mode="out-in">
      <Component :is="Component" :key="route.path"></Component>
    </Transition>
  </router-view>

  <FooterTitle
    :text-color-footer="$route?.meta?.textColorFooter"
    class="realtive z-20 max-md:hidden"
    :class="$route.meta.opacity ? 'opacity-20' : ''"
  />

  <div class="absolute inset-0 z-0 h-[100svh] w-screen">
    <Transition name="fade-page">
      <div v-if="$route.meta.background === 'image'">
        <div
          class="absolute inset-0 z-10 h-[100svh] w-screen bg-black opacity-70"
        ></div>
        <img
          src="/teaching-hans.png"
          alt="Hans"
          class="absolute inset-0 h-[100svh] w-screen object-cover"
        />
      </div>

      <video
        v-else-if="$route.meta.background === 'video'"
        autoplay
        loop
        muted
        poster="/Clever-Hans_0.jpg"
        class="fixed inset-0 h-[100svh] w-screen object-cover"
      >
        <source src="/Clever-Hans.mp4" type="video/mp4" />
        <source src="/Clever-Hans.webm" type="video/webm" />
      </video>

      <div
        v-else-if="$route.meta.background === 'black'"
        class="absolute inset-0 z-10 h-[100svh] w-screen bg-black"
      ></div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { RouterView } from 'vue-router'

import NavBar from './components/NavBar.vue'
import FooterTitle from './components/FooterTitle.vue'

const city = ref('')

const GEO_API = `https://get.geojs.io/v1/ip/geo.json`

onMounted(async () => {
  const locationRes = await fetch(GEO_API, { method: 'GET' })
  const locationData = await locationRes.json()

  if (locationData.city) {
    city.value = locationData.city
  }
})
</script>

<style>
body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.fade-page-enter-active,
.fade-page-leave-active {
  transition: opacity 0.45s;
}

.fade-page-enter-from,
.fade-page-leave-to {
  opacity: 0;
}

.fade-page-leave-active .block-notes {
  transition: transform 0.45s;
}
.fade-page-leave-to .block-notes {
  transform: translateY(-150px);
}
</style>
