<template>
  <section class="flex h-[100svh]">
    <div class="relative z-20 flex flex-col justify-between py-10 px-12">
      <div class="h-6 w-6 rounded-full bg-white"></div>
      <div class="h-6 w-6 rounded-full bg-white"></div>
      <div class="h-6 w-6 rounded-full bg-white"></div>
      <div class="h-6 w-6 rounded-full bg-white"></div>
    </div>
    <div class="relative z-20 w-full text-white">
      <p
        class="absolute top-0 left-0 space-x-6 pt-10 font-label text-sm uppercase"
      >
        <span>Clever Hans</span> <span> X </span>
        <span> {{ userReport?.[0]?.attributes?.Idea[0]?.Email }}</span>
      </p>

      <p class="absolute top-10 right-12 font-label">01</p>

      <div
        class="flex h-full max-w-2xl flex-col justify-end space-y-10 pb-24 font-base 2xl:pb-40"
      >
        <h1
          class="font-bold"
          :class="
            userReport?.[0]?.attributes?.Idea[0]?.Title.length > 70
              ? 'text-5xl'
              : 'text-7xl'
          "
        >
          {{ $t("pdf.title") }}
          {{ userReport?.[0]?.attributes?.Idea[0]?.Title }}
        </h1>

        <p class="text-2xl font-bold">
          {{ $t("pdf.description") }}
        </p>
      </div>

      <p class="absolute bottom-9 right-6 font-label uppercase">
        www.clever-hans.com
      </p>
    </div>

    <div>
      <div class="absolute inset-0 z-10 bg-black opacity-50"></div>
      <img
        class="absolute inset-0 h-[100svh] w-screen object-cover"
        :src="images[randomNumbers[0]]"
        alt="Inspirated Image"
      />
    </div>
  </section>

  <section
    class="body-content relative flex h-[100svh]"
    v-for="(section, idx) in sections"
    :key="idx"
    :style="{ backgroundColor: section?.color }"
  >
    <div class="relative z-20 flex flex-col justify-between py-10 px-12">
      <div
        class="h-6 w-6 rounded-full"
        :class="section?.dots ? 'bg-white' : 'bg-gray-300'"
      ></div>
      <div
        class="h-6 w-6 rounded-full"
        :class="section?.dots ? 'bg-white' : 'bg-gray-300'"
      ></div>
      <div
        class="h-6 w-6 rounded-full"
        :class="section?.dots ? 'bg-white' : 'bg-gray-300'"
      ></div>
      <div
        class="h-6 w-6 rounded-full"
        :class="section?.dots ? 'bg-white' : 'bg-gray-300'"
      ></div>
    </div>

    <div
      class="flex flex-col pr-12"
      :class="(idx === 0 || idx === 8) && 'w-full'"
    >
      <p
        class="subtitle-text relative z-20 space-x-6 pt-10 font-label text-sm uppercase"
        :class="
          (idx === 8 || idx === 10 || idx === 13 || idx === 5) && 'text-white'
        "
      >
        <span>Clever Hans</span> <span> X </span>
        <span>
          {{ userReport?.[0]?.attributes?.Idea[0]?.Email }}
        </span>
      </p>

      <p
        class="absolute top-10 right-12 z-20 font-label"
        :class="
          (idx === 8 || idx === 10 || idx === 13 || idx === 5) && 'text-white'
        "
      >
        {{ idx < 8 ? 0 : "" }}{{ idx + 2 }}
      </p>

      <div class="space-x-24 font-base">
        <div class="whitespace-pre-line">
          <h1
            class="relative z-20 pt-12 pb-10 font-bold"
            :class="[
              idx === 0 ? 'text-center text-2xl' : 'text-5xl',
              (idx === 8 || idx === 10 || idx === 13) && 'text-white',
              idx === 5 && 'max-w-xl',
              idx === 8 && 'text-center',
            ]"
          >
            {{ section?.title }}
          </h1>
          <section v-if="idx === 3" class="flex flex-wrap gap-4">
            <div v-for="img in section?.content.data" :key="img">
              <div>
                <img
                  class="h-56 w-fit object-cover"
                  :src="img?.attributes?.formats?.small.url"
                  alt="Img"
                />
              </div>
            </div>
          </section>
          <div
            v-else-if="idx === 0"
            class="names flex flex-col text-center"
            v-html="section?.content"
          ></div>
          <div
            v-else-if="idx === 6"
            class="important-words mr-10 text-center"
            v-html="section?.content"
          ></div>
          <div v-else-if="idx === 8">
            <div
              class="thinkers relative z-20 text-center text-white"
              v-html="section?.content"
            ></div>
            <div>
              <div class="absolute inset-0 z-10 bg-black opacity-50"></div>
              <img
                class="absolute inset-0 h-[100svh] w-screen object-cover"
                :src="images[randomNumbers[1]]"
                alt="Inspirated Image"
              />
            </div>
          </div>
          <div
            v-else-if="idx === 7 || idx === 9"
            class="grid max-w-3xl grid-cols-2 gap-x-24"
            v-html="section?.content"
          ></div>
          <div v-else-if="idx === 10 || idx === 13">
            <div
              class="topics relative z-20 grid grid-cols-2 grid-rows-3 gap-x-16 gap-y-8 text-white"
              v-html="section?.content"
            ></div>
            <div>
              <div class="absolute inset-0 z-10 bg-black opacity-50"></div>
              <img
                v-if="idx === 10"
                class="absolute inset-0 h-[100svh] w-screen object-cover"
                :src="images[randomNumbers[2]]"
                alt="Inspirated Image"
              />
              <img
                v-else
                class="absolute inset-0 h-[100svh] w-screen object-cover"
                :src="images[randomNumbers[3]]"
                alt="Inspirated Image"
              />
            </div>
          </div>
          <div v-else-if="idx === 5" class="flex justify-between">
            <!-- <div
            v-else-if="idx === 5"
            class="max-w-3xl space-y-5 bg-red-500"
            v-html="section?.content"
          > -->
            <p
              class="relative z-10 max-w-xl space-y-5"
              v-html="section?.content"
            ></p>
            <div
              class="absolute right-0 top-0 z-10 h-screen w-3/12 bg-black opacity-50"
            ></div>
            <img
              class="absolute right-0 top-0 h-screen w-3/12 object-cover"
              :src="images[randomNumbers[4]]"
              alt="Inspirated Image"
            />
          </div>
          <div
            v-else
            class="max-w-3xl space-y-5"
            v-html="section?.content"
          ></div>
        </div>
      </div>
    </div>

    <p
      class="absolute right-6 bottom-9 z-20 font-label uppercase"
      :class="
        (idx === 8 || idx === 10 || idx === 13 || idx === 5) && 'text-white'
      "
    >
      www.clever-hans.com
    </p>
  </section>

  <section class="flex h-[100svh] bg-black">
    <div class="relative z-20 flex flex-col justify-between py-10 px-12">
      <div class="h-6 w-6 rounded-full bg-white"></div>
      <div class="h-6 w-6 rounded-full bg-white"></div>
      <div class="h-6 w-6 rounded-full bg-white"></div>
      <div class="h-6 w-6 rounded-full bg-white"></div>
    </div>
    <div class="relative z-20 w-full text-white">
      <p
        class="absolute top-0 left-0 space-x-6 pt-10 font-label text-sm uppercase"
      >
        <span>Clever Hans</span> <span> X </span>
        <span>{{ userReport?.[0]?.attributes?.Idea[0]?.Email }}</span>
      </p>

      <p v-if="sections" class="absolute top-10 right-12 font-label">
        {{ sections.length + 2 }}
      </p>

      <div class="flex h-full max-w-xl items-end pb-9 font-base">
        <p class="text-3xl font-bold">
          {{ $t("pdf.projectBy") }}
        </p>
      </div>

      <p class="absolute bottom-9 right-6 font-label uppercase">
        www.clever-hans.com
      </p>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";

import { useI18n } from "vue-i18n";

const { t } = useI18n();

import { fetchPdf, fetchReport } from "../services/formService";

const images = [
  "/img1.jpg",
  "/img2.jpg",
  "/img3.jpg",
  "/img4.jpg",
  "/img5.jpg",
  "/img6.jpg",
  "/img7.jpg",
  "/img8.jpg",
  "/img9.jpg",
  "/img10.jpg",
  "/img11.jpg",
  "/img12.jpg",
  "/img13.jpg",
  "/img14.jpg",
  "/img15.jpg",
  "/img16.jpg",
  "/img17.jpg",
  "/img18.jpg",
  "/img19.jpg",
  "/img20.jpg",
  "/img21.jpg",
  "/img22.jpg",
  "/img23.jpg",
  "/img24.jpg",
  "/img25.jpg",
  "/img26.jpg",
  "/img27.jpg",
  "/img28.jpg",
  "/img29.jpg",
  "/img30.jpg",
  "/img31.jpg",
  "/img32.jpg",
  "/img33.jpg",
  "/img34.jpg",
  "/img35.jpg",
  "/img36.jpg",
  "/img37.jpg",
  "/img38.jpg",
  "/img39.jpg",
  "/img40.jpg",
  "/img41.jpg",
  "/img42.jpg",
  "/img43.jpg",
  "/img44.jpg",
  "/img45.jpg",
];

function generarNumerosUnicos(min, max, cantidad) {
  if (max - min + 1 < cantidad) {
    throw new Error(
      "No es posible generar la cantidad de números únicos en el rango proporcionado."
    );
  }

  let random = [];

  while (random.length < cantidad) {
    const number = Math.floor(Math.random() * (max - min + 1)) + min;
    if (!random.includes(number)) {
      random.push(number);
    }
  }

  return random;
}

const randomNumbers = generarNumerosUnicos(1, 46, 5);

const route = useRoute();

const pdfTitles = ref();
const userReport = ref();

const main = async () => {
  await fetchPdf().then((data) => {
    pdfTitles.value = data?.data?.attributes;
  });

  await fetchReport(Number(route.params["id"])).then((data) => {
    userReport.value = data?.data;
  });
};

main();

onMounted(() => {});

const sections = computed(() => {
  if (userReport.value) {
  }
  if (pdfTitles.value && userReport.value) {
    return [
      {
        color: "#F3E900",
        dots: true,
        title: t("pdf.fields.names"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.NameConcept,
      },
      {
        color: "#B2CCFD",
        dots: true,
        title: t("pdf.fields.conceptSummary"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.ConceptSummary,
      },
      {
        color: "#FFFFFF",
        dots: false,
        title: t("pdf.fields.formalization"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Formalization,
      },
      {
        color: "#B2CCFD",
        dots: true,
        title: t("pdf.fields.images"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Image,
      },
      {
        color: "#FFFFFF",
        dots: false,
        title: t("pdf.fields.userExperience"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.UserExperience,
      },
      {
        color: "#FFFFFF",
        dots: false,
        title: t("pdf.fields.questions"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Questions,
      },
      {
        color: "#B2CCFD",
        dots: true,
        title: t("pdf.fields.words"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Words,
      },
      {
        color: "#FFFFFF",
        dots: false,
        title: t("pdf.fields.artworks"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Artworks,
      },
      {
        color: "#FFFFFF",
        dots: false,
        title: t("pdf.fields.thinkers"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Experts,
      },
      {
        color: "#B2CCFD",
        dots: true,
        title: t("pdf.fields.books"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Books,
      },
      {
        color: "#FFFFFF",
        dots: true,
        title: t("pdf.fields.topics"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Topics,
      },
      {
        color: "#F3E900",
        dots: true,
        title: t("pdf.fields.facts"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Facts,
      },
      {
        color: "#FFFFFF",
        dots: false,
        title: t("pdf.fields.materials"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Materials,
      },
      {
        color: "#B2CCFD",
        dots: true,
        title: t("pdf.fields.metaphors"),
        content: userReport.value?.[0]?.attributes?.Idea[0]?.Metaphors,
      },
    ];
  }
});
</script>

<style scoped>
.body-content :deep(h2) {
  @apply text-lg font-bold;
}

.body-content :deep(li) {
  @apply ml-4 list-decimal;
}

.names:deep(strong) {
  font-size: 42px;
}

.important-words:deep(ol) {
  @apply grid grid-cols-3 space-x-10;
}
.important-words:deep(ol > li) {
  @apply list-none text-center;
}

.important-words:deep(li > strong) {
  font-size: 34px;
  line-height: 3rem;
  @apply -mt-4 block pb-5;
}

.important-words:deep(ol > li > p:first-child) {
  @apply pb-5 pt-14 text-2xl;
}

.thinkers:deep(p) {
  @apply mx-auto max-w-2xl pb-2 text-2xl;
}
.thinkers:deep(div) {
  @apply text-lg;
}

.topics:deep(strong) {
  @apply block pb-2 text-xl;
}
.topics:deep(p) {
  @apply max-w-2xl;
}
</style>
