<template>
  <section>
    <Teleport to="body">
      <VideoBlock :is-open="showReel || !hasVisit" @closeVideo="closeVideo" />
    </Teleport>
    <div
      class="flex flex-col items-center justify-center space-y-6 text-center max-md:pb-24 2xl:space-y-10"
    >
      <h1 class="sr-only">Clever Hans</h1>
      <h2
        class="max-w-xs font-base text-2xl font-bold text-white md:max-w-xl md:text-5xl 2xl:text-6xl"
        v-html="$t('home.title')"
      ></h2>
      <p
        class="max-w-xs font-base text-xl text-white md:max-w-2xl md:text-2xl 2xl:text-4xl"
        v-html="$t('home.description')"
      ></p>

      <div
        class="flex space-x-6 font-label max-md:flex-col max-md:space-y-2 max-md:space-x-0"
      >
        <router-link
          v-if="countGenerations <= 1"
          to="/brief"
          class="flex h-14 w-56 items-center justify-center rounded-full bg-white uppercase text-black transition-colors duration-300 hover:bg-black hover:text-white 2xl:w-60"
          @click="onClickSound"
        >
          {{ $t('home.buttons.beta') }}
        </router-link>
        <router-link
          v-else
          to="/credits"
          class="flex h-14 w-56 items-center justify-center rounded-full bg-white uppercase text-black transition-colors duration-300 hover:bg-black hover:text-white 2xl:w-60"
          @click="onClickSound"
        >
          {{ $t('home.buttons.credits') }}
        </router-link>
        <router-link
          to="/access"
          class="flex h-14 w-56 items-center justify-center rounded-full bg-white uppercase text-black transition-colors duration-300 hover:bg-black hover:text-white 2xl:w-60"
          @click="onClickSound"
        >
          {{ $t('home.buttons.access') }}
        </router-link>
        <button
          class="h-14 w-56 rounded-full bg-black uppercase text-white transition-colors duration-300 hover:bg-white hover:text-black 2xl:w-60"
          @click="showReel = !showReel"
        >
          {{ $t('home.buttons.reel') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { idle } from '../sounds'
import { hasSound } from '../store'
import VideoBlock from '../components/VideoBlock.vue'

const showReel = ref(false)
const countGenerations = ref()
const hasVisit = ref(localStorage.hasVisit || false)

const closeVideo = () => {
  showReel.value = false
  hasVisit.value = true
  localStorage.setItem('hasVisit', 'true')
}

const onClickSound = () => {
  hasSound.value && idle.play()
}

countGenerations.value = Number(localStorage.generations || 0)
</script>
