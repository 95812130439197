import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import EN from '../src/locale/en.json'
import ES from '../src/locale/es.json'
import CAT from '../src/locale/cat.json'

import './assets/base.css'

const locales = ['ES', 'CA', 'EN']

const windowLang = window.navigator.language.substring(0, 2).toLocaleUpperCase()

const initialLanguage =
  document.cookie.split('locale=')[1] !== undefined
    ? document.cookie.split('locale=')[1].substring(0, 2)
    : locales.some((el) => el === windowLang)
    ? windowLang
    : 'EN'

const i18n = createI18n({
  locale: initialLanguage,
  fallbackLocale: 'EN',
  messages: {
    EN: EN,
    ES: ES,
    CAT: CAT,
  },
  legacy: false,
})

const app = createApp(App)

app.use(i18n)
app.use(router)

app.mount('#app')
